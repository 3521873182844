import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Map from "@/views/Map.vue";
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import ModuleSelector from "@/views/ModuleSelector.vue";
import Views from "@/views/Views.vue";
import Environments from "@/views/Environments.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/Logistics",
    name: "Logistics",
    component: Map,
  },
  {
    path: "/Installation",
    name: "Installation",
    component: Map,
  },
  {
    path: "/Services",
    name: "Services",
    component: Map,
  },
  {
    path: "/MieleProfessionals",
    name: "MieleProfessionals",
    component: Map,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/moduleselector",
    name: "ModuleSelector",
    component: ModuleSelector,
  },
  {
    path: "/environments",
    name: "Environments",
    component: Environments,
  },
  {
    path: "/views/*",
    name: "Views",
    component: Views,
  },
  {
    path: "/views",
    name: "Views",
    component: Views,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
