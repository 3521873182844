var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticClass: "section is-small",
        staticStyle: { "max-width": "500px", margin: "0 auto" },
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("header", { staticClass: "card-header" }, [
            _c("p", { staticClass: "card-header-title" }, [
              _vm._v("Select a Module"),
            ]),
          ]),
          _c("div", { staticClass: "card-image" }, [
            _c("figure", { staticClass: "image" }, [
              _c("img", {
                attrs: {
                  src: "https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FA_simple_and_abstract_black_and_white_illustration_converted.png?alt=media&token=3500f3d7-0a52-43a2-b7df-62b5552ce94e",
                  alt: "Placeholder image",
                },
              }),
            ]),
          ]),
          _c("footer", { staticClass: "card-footer" }, [
            _c(
              "a",
              {
                staticClass: "card-footer-item has-text-success",
                attrs: { href: "https://demooptima.web.app" },
              },
              [_vm._v("Routing Module")]
            ),
            _c(
              "a",
              {
                staticClass: "card-footer-item has-text-success",
                attrs: { href: "/home" },
              },
              [_vm._v("Network Module")]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }