<template>
  <div>
    <div v-if="!loading">
      <div class="title is-7">Add Location In {{ Region.name }}</div>
      <div class="is-size-7 has-text-danger">
        {{ msg }}
      </div>
      <div class="is-flex is-flex-direction-row is-align-items-baseline"></div>
      <div class="notification is-white">
        <div class="columns" style="width: 100%">
          <div class="column is-half">
            <label class="label is-small">Location Name</label>
            <input
              class="input is-small"
              v-model="DistributionZoneName"
              type="text"
            />
          </div>
          <div class="column is-half">
            <label class="label is-small">Search Address</label>
            <input
              type="text"
              class="input is-small"
              ref="autocomplete"
              placeholder="Enter a location"
            />
            <p class="help is-size-7 is-warning m-0 p-0" style="height: 20px">
              {{ err_message }}
            </p>
          </div>
        </div>
        <div class="columns" style="width: 100%">
          <div class="column is-one-fifth">
            <label class="label is-small">Door Number</label>
            <input class="input is-small" v-model="DoorNumber" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">Apartment</label>
            <input class="input is-small" v-model="Apartment" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">Street</label>
            <input class="input is-small" v-model="Street" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">City</label>
            <input class="input is-small" v-model="City" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">Postal Code</label>
            <input
              class="input is-small"
              v-model="PostalCode"
              type="text"
              placeholder="T4A0X3"
            />
          </div>
        </div>
        <div class="columns" style="width: 100%">
          <div class="column is-one-quarter">
            <label class="label is-small is-one-quarter">Type</label>
            <div class="control">
              <div class="select is-small is-fluid">
                <select v-model="DistributionZoneType" style="width: 100%">
                  <template v-for="type in store.getters.DistributionZoneTypes">
                    <option :value="type" :key="type.value">
                      {{ type.label }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <label class="label is-small">Facility</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="Facility" style="width: 100%">
                  <template v-for="type in store.getters.Facility">
                    <option :value="type" :key="type">{{ type }}</option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div
            class="column is-one-half"
            v-if="DistributionZoneType.label === 'STORE'"
          >
            <label class="label is-small">Client</label>
            <div class="control">
              <div class="select is-small is-fluid" style="width: 100%">
                <select v-model="Vendor" style="width: 100%">
                  <option
                    class="is-size-7"
                    v-for="vendor in store.state.vendors"
                    :selected="Vendor === vendor.Id"
                    :key="vendor.Id"
                    :value="vendor.Id"
                  >
                    {{ vendor.businessUnit }}***{{ vendor.subBusinessUnit }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-quarter">
            <label class="label is-small">Country</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="Country" style="width: 100%">
                  <template v-for="country in store.getters.Countries">
                    <option
                      :value="country"
                      :key="country.label"
                      :selected="Country.label === country.label"
                    >
                      {{ country.label }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="column">
            <label class="label is-small"
              >Coordinates (Lat/Lng)
              <span
                ><button
                  class="button is-small is-primary p-1 ml-1"
                  style="height: 20px"
                  v-bind:class="{ 'is-loading': geocoding_loading }"
                  @click="getCoordinates(Street, City, PostalCode, Province)"
                >
                  Get coordinates
                </button>
              </span></label
            >
            <div class="columns">
              <div class="column px-0">
                <input
                  class="input is-small"
                  v-model.number="Latitude"
                  type="number"
                  placeholder="Latitude"
                />
              </div>
              <div class="column pl-0">
                <input
                  class="input is-small"
                  v-model.number="Longitude"
                  type="number"
                  placeholder="Longitude"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-quarter">
            <label class="label is-small">Phone</label>
            <input
              class="input is-small"
              type="tel"
              v-model="PhoneNumber"
              placeholder="xxxxxxxxxx"
            />
          </div>
          <div class="column is-one-quarter">
            <label class="label is-small">Size (Sq. Ft.)</label>
            <input
              class="input is-small"
              v-model.number="Size"
              type="number"
              placeholder="Size"
            />
          </div>
          <div class="column is-one-quarter">
            <label class="label is-small">Number Of Employees</label>
            <input
              class="input is-small"
              v-model.number="NumberOfEmployees"
              type="number"
              placeholder="Number Of Employees"
            />
          </div>
        </div>
        <div
          class="is-white is-flex is-flex-wrap-wrap"
          v-if="DistributionZoneType.label !== 'STORE'"
        >
          <label class="tag m-2 checkbox">
            <input class="is-small" v-model="IsFinalMile" type="checkbox" />
            Is Final Mile
          </label>
          <label class="tag m-2 checkbox">
            <input class="is-small" v-model="IsForReverse" type="checkbox" />
            Used For Reverse
          </label>
          <label class="tag m-2 checkbox">
            <input class="is-small" v-model="AllowCrossdock" type="checkbox" />
            Crossdock
          </label>
          <label class="tag m-2 checkbox">
            <input
              class="is-small"
              v-model="AllowOvernightReception"
              type="checkbox"
            />
            Overnight Reception
          </label>
          <label class="tag m-2 checkbox">
            <input
              class="is-small"
              v-model="shouldConsiderFuelSurcharge"
              type="checkbox"
            />
            Include In Fuel Surcharge Calculation
          </label>
        </div>
        <div class="my-3">Data Source</div>
        <label class="tag m-2 checkbox">
          <input class="is-small" v-model="IsCDSRegion" type="checkbox" />
          Shyftbase
        </label>
        <label class="tag m-2 checkbox">
          <input class="is-small" v-model="IsCustomerRegion" type="checkbox" />
          External
        </label>
      </div>
      <div class="notification is-white has-text-centered is-fullwidth">
        <button
          class="button is-small is-success"
          @click="
            () => {
              saveToFirestore();
            }
          "
        >
          Save
        </button>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
      "
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { hubsCollection, firestoreInstance } from "../store/fireStore";
import store from "../store";
import axios from "axios";

export default {
  components: {
    PulseLoader,
  },
  data() {
    return {
      clientName: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
      DistributionZoneName: "",
      DistributionZoneId: "",
      Street: "",
      DoorNumber: "",
      Apartment: "",
      City: "",
      Province: "",
      PostalCode: "",
      DistributionZoneType: { value: 0, label: "PRIMARY" },
      Facility: "WAREHOUSE",
      Size: null,
      NumberOfEmployees: null,
      PhoneNumber: "",
      AllowCrossdock: false,
      IsFinalMile: false,
      IsForReverse: false,
      AllowOvernightReception: false,
      IsCDSRegion: false,
      shouldConsiderFuelSurcharge: false,
      IsCustomerRegion: false,
      loading: false,
      msg: "",
      Vendor: "",
      Color: "#007D6A",
      Region: {
        name: "",
        id: "",
      },
      store,
      Latitude: null,
      Longitude: null,
      err_message: "",
      geocoding_loading: false,
      TimeZone: "",
      Country: {
        label: "",
        value: "",
      },
    };
  },
  props: {
    coordinates: {
      type: Object,
    },
    region: {
      type: String,
    },
    timeZone: {
      type: String,
    },
  },
  mounted() {
    this.Latitude = this.coordinates.lat;
    this.Longitude = this.coordinates.lng;
    this.TimeZone = this.timeZone;
    this.err_message = "";
    const input = this.$refs.autocomplete;

    const options = {
      types: ["geocode"], // Restrict to addresses
      // Add any additional options as needed
    };

    // eslint-disable-next-line
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  },
  watch: {
    DistributionZoneType: function (val) {
      // TODO: WE NEED TO SET THIS SETTING ON THE CONFIGURATIONS
      if (val.label === "STORE") {
        this.Color = "#DE5A35";
      } else if (val.label === "STEM") {
        this.Color = "#00d0c4";
      } else if (val.label === "SECONDARY") {
        this.Color = "#1184E8";
      } else if (val.label === "PRIMARY") {
        this.Color = "#007D6A";
      }
    },
  },
  created() {
    const regions = store.state.regions;
    const currentRegionSelected = store.state.region;
    const selectedRegion = regions.find(
      (region) => region.id === currentRegionSelected
    );
    this.Region.name = selectedRegion.name;
    this.Region.id = selectedRegion.id;
    this.Province = selectedRegion.province;
  },
  methods: {
    closeModal() {
      this.close();
    },
    async saveToFirestore() {
      const {
        DistributionZoneName,
        Street,
        DoorNumber,
        Apartment,
        City,
        Province,
        PostalCode,
        DistributionZoneType,
        Facility,
        Size,
        NumberOfEmployees,
        PhoneNumber,
        AllowCrossdock,
        IsFinalMile,
        IsForReverse,
        AllowOvernightReception,
        // coordinates,
        Color,
        Vendor,
        Latitude,
        Longitude,
        TimeZone,
        Country,
      } = this;
      if (
        DistributionZoneName &&
        Street &&
        City &&
        PostalCode &&
        Country.label
      ) {
        if (Vendor === "" && DistributionZoneType.label === "STORE") {
          this.msg = "Please Select a Vendor";
          return;
        }
        try {
          if (process.env.VUE_APP_FIREBASE_PID !== "metro-logistics") {
            try {
              await axios.post(
                `https://us-central1-${process.env.VUE_APP_FIREBASE_PID}.cloudfunctions.net/VALIDATIONS-uniqueChecks`,
                {
                  type: "distributionZone",
                  data: {
                    name: DistributionZoneName,
                  },
                }
              );
            } catch (error) {
              this.loading = false;
              if (
                error.response &&
                error.response.data &&
                error.response.data.error === "distributionZone-exists"
              ) {
                this.msg = `${DistributionZoneName} already exists. Please use a different name.`;
              } else {
                this.msg = "Something went wrong, please try again.";
              }
              return;
            }
          }

          const docRef = await hubsCollection.doc();
          const DistributionZoneId = docRef.id;
          const toFirestoreCoordinates = new firestoreInstance.GeoPoint(
            parseFloat(String(Latitude)),
            parseFloat(String(Longitude))
          );
          await docRef.set(
            {
              CustomFields: {
                IsCDSRegion: this.IsCDSRegion || false,
                IsCustomerRegion: this.IsCustomerRegion || false,
                shouldConsiderFuelSurcharge:
                  this.shouldConsiderFuelSurcharge || false,
              },
              DistanceBetweenHubs: null,
              AllowCrossdock,
              IsFinalMile,
              IsForReverse,
              AllowOvernightReception,
              Apartment,
              City,
              Color,
              Center: toFirestoreCoordinates,
              DoorNumber,
              Facility,
              DistributionZoneName,
              DistributionZoneId,
              DistributionZoneType: DistributionZoneType.value,
              PhoneNumber,
              PostalCode,
              Province,
              Region: this.Region.id,
              Size,
              NumberOfEmployees,
              Street,
              Vendor: DistributionZoneType === "STORE" ? Vendor : "",
              TimeZone,
              Country: Country.label,
              CountryCode: Country.value,
            },
            { merge: true }
          );
          this.loading = false;
          this.close();
          this.msg = "";
        } catch (err) {
          console.log("ERR: ", err);
          this.msg = "Something went wrong, please try again.";
        }
      } else {
        this.msg = "Name, street, city, province and postal code are required";
      }
    },
    async getCoordinates(street, city, postalCode, province, country) {
      this.geocoding_loading = true;
      const geocode_url =
        "https://us-central1-core-v2-34762.cloudfunctions.net/GEOCODE-functions-getOptimizerGeocode";
      const geocode_data = [
        {
          address:
            street +
            " " +
            city +
            " " +
            province +
            " " +
            country +
            " " +
            postalCode,
        },
      ];
      if (!city || !postalCode || !province || !country) {
        this.geocoding_loading = false;
        this.err_message = "Please enter a valid address";
        return;
      }
      await axios
        .post(geocode_url, { data: geocode_data })
        .then((response) => {
          this.geocoding_loading = false;
          if (response.data && response.data[0].geocodes) {
            this.Latitude = response.data[0].geocodes.lat;
            this.Longitude = response.data[0].geocodes.long;
          } else {
            this.err_message =
              "Coordinates not found. Please check the address or you can manually enter the coordinates";
          }
        })
        .catch((error) => {
          console.log("geocoding error: ", error);
          this.geocoding_loading = false;
          this.err_message = "Internal error. Please contact Shyftbase team";
        });
      this.geocoding_loading = false;
    },
    async onPlaceChanged() {
      this.err_message = "";
      const addressObject = this.autocomplete.getPlace();

      const address = addressObject.address_components;
      const province = address
        ? address.filter((address_component) =>
            ["administrative_area_level_1", "administrative_area_level_2"].some(
              (word) => address_component.types.includes(word)
            )
          )
        : null;

      // if (!address.map(a => a.long_name).includes(this.Province)) {
      //   this.err_message =
      //     "zone cannot be outside of the province of " + this.Province;
      //   return;
      // }

      const streetAddress = address
        ? address
            .filter((address_component) =>
              [
                "floor",
                "room",
                "route",
                "street_number",
                "street_address",
              ].some((word) => address_component.types.includes(word))
            )
            .map((a) => a.short_name)
            .join(" ")
        : null;

      const city = address
        ? address.filter((address_component) =>
            ["locality", "colloquial_area"].some((word) =>
              address_component.types.includes(word)
            )
          )
        : null;

      const postalCode = address
        ? address.filter((address_component) =>
            ["postal_code"].some((word) =>
              address_component.types.includes(word)
            )
          )
        : null;

      const country = address
        ? address.filter((address_component) =>
            ["country"].some((word) => address_component.types.includes(word))
          )
        : null;
      const _street = streetAddress;
      const _city = city.length && city[0] ? city[0].long_name : "";
      const _postalCode = postalCode.length ? postalCode[0].short_name : "";
      // TBD: Is it always full name? short name? all capital?
      const _province = province.length ? province[0].long_name : this.Province;
      const _country = country.length ? country[0].long_name : "";
      const _countryCode = country.length ? country[0].short_name : "";

      this.Street = _street;
      this.City = _city;
      this.PostalCode = _postalCode;
      this.Province = _province;
      this.Country = {
        label: _country,
        value: _countryCode,
      };

      await this.getCoordinates(
        _street,
        _city,
        _postalCode,
        _province,
        _country
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
  background: #e93434;
  color: #ffffff;
}

select,
.select {
  width: 100%;
}
</style>
