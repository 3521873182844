import firebase from 'firebase'
import { v4 as uuidv4 } from 'uuid';
import { addHistory, getSelectedZone, getZoneByRegion } from '@/store/mongoStore'
import {
  INSERT_ZONE_INFO,
  EDIT_ZONE_INFO,
} from '@/exports/historyConstants'
import { Zone } from '../typings/interface'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB,
  projectId: process.env.VUE_APP_FIREBASE_PID,
  storageBucket: process.env.VUE_APP_FIREBASE_SB,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MSI,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

// optimizer routes
const routingConfig = {
  apiKey: 'AIzaSyDGPd4v56XggEEtXV6b58u4X9lHHRywLnU',
  authDomain: 'shyft-optimizer.firebaseapp.com',
  databaseURL: 'https://shyft-optimizer.firebaseio.com',
  projectId: 'shyft-optimizer',
  storageBucket: 'shyft-optimizer.appspot.com',
  appId: '1:680605399933:web:4c5d4bfc5fc902e1ee2a14'
}

firebase.initializeApp(firebaseConfig)
firebase.initializeApp(routingConfig, 'routing')

export const db = firebase.firestore()
export const firestoreInstance = firebase.firestore
export const hubsCollection = db.collection('DISTRIBUTIONZONES')
export const vehiclesCollection = db.collection('FLEET')
export const sharedViewsCollection = db.collection('CONFIGURATIONS/MAPFLEET/SHARED_VIEWS')
export const vendorsCollection = db.collection('VENDORS')
export const zonesInfoCollection = db.collection(`${process.env.VUE_APP_FIRESTORE_ZONES}`)
export const regionsCollection = db.collection('REGIONS')
export const configurationsCollection = db.collection('CONFIGURATIONS')
export const globalUnitsCollection = db.collection('CONFIGURATIONS/OPTIONS/GlobalUnits')

export const getDeliveries = firebase.functions(firebase.app('routing')).httpsCallable('OPTIMIZER-getRoutes')
export const auth = firebase.auth()
export const { currentUser } = auth

export const insertZoneInfo = async (zoneInfo: any, calledUndo = false) => {
  const { id } = await zonesInfoCollection.add({ ...zoneInfo })
  const payloadData = {
    zoneId: id
  }
  if (!calledUndo) {
    await addHistory(INSERT_ZONE_INFO, payloadData)
  }
  return true
}

export const insertZoneInfoWithID = async ({
  color, value, selected, environment,
}: Zone, id: string, calledUndo = false) => {
  await zonesInfoCollection.doc(id).set({
    color, value, selected, environment
  })
  const payloadData = {
    zoneId: id
  }
  if (!calledUndo) {
    await addHistory(INSERT_ZONE_INFO, payloadData)
  }
  return true
}

export const deleteZoneInfo = async (id: string, calledUndo = false) => {
  const query = await zonesInfoCollection
    .doc(id)
    .get()
  if (query.exists) {
    const zones: any = await getSelectedZone(id)
    const vehicleQuery = await vehiclesCollection.where('Zone', '==', id).get()
    if (!vehicleQuery.empty) {
      return false
    }
    if (!(zones.length > 0)) {
      await zonesInfoCollection
        .doc(id)
        .delete()
      return true
    } else {
      return false
    }
  }
}

export const editZoneInfo = async (id: string, zone: Zone, calledUndo = false) => {
  const { key, value, selected, environment } = zone
  try {
    const query = await zonesInfoCollection
      .doc(id)
      .get()
    const payloadData = {
      new: {
        ...query.data(), key, value, selected, environment
      },
      current: {
        ...query.data()
      },
      zoneId: id
    }
    zonesInfoCollection
      .doc(id)
      .update({
        key, value, selected, environment
      })
    if (!calledUndo) {
      await addHistory(EDIT_ZONE_INFO, payloadData)
    }
    return true
  } catch (err) {
    return false
  }
}

export const addRegion = async (region: string) => {
  const { id } = await regionsCollection.add({
    name: region
  })
  await regionsCollection.doc(id).set({
    id
  }, { merge: true })
  return id
}

export const addSharedView = async (view: { name: string, region: string, environment: string }) => {
  const { id } = await sharedViewsCollection.add({
    name: view.name,
    region: view.region,
    environment: view.environment,
    urlId: uuidv4() /* id for the shared view */
  })
  return id
}

export const deleteRegion = async (id: string) => {
  const zones = await getZoneByRegion(id)
  const hubQuery = await hubsCollection.where('Region', '==', id).get()
  const vehicleQuery = await vehiclesCollection.where('Region', '==', id).get()
  if (!vehicleQuery.empty || !hubQuery.empty || zones) {
    return false
  }
  const query = await regionsCollection
    .doc(id)
    .get()
  if (query.exists) {
    await regionsCollection.doc(id).delete()
  }
  return true
}

export const deleteSharedView = async (id: string) => {
  console.log('delete :>> ', id);
  await sharedViewsCollection.doc(id).delete()
  return true
}
