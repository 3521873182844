<template>
  <div class="container" style="width: fit-content">
    <div v-if="!loading">
      <div class="control">
        <div class="tags has-addons">
          <span class="tag is-success">{{ Region.name }}</span>
          <span class="tag is-grey">{{ DistributionZoneName }}</span>
        </div>
      </div>
      <div class="is-size-7 has-text-warning">
        {{ msg }}
      </div>
      <div class="columns" style="width: 100%">
        <!-- should be removed as soon as all data is setteld -->
        <div class="column is-one-quarter">
          <label class="label is-small">Region</label>
          <div class="control">
            <div class="select is-small is-fluid">
              <select :disabled="!editable" v-model="Region.id" style="width: 100%">
                <template v-for="region in store.state.regions">
                  <option :value="region.id" :key="region.id" :selected="region.id === Region.id">
                    {{ region.name }}
                  </option>
                </template>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="notification is-white">
        <div class="columns" style="width: 100%">
          <div class="column is-half">
            <label class="label is-small">Search Address</label>
            <input type="text" class="input is-small" ref="autocomplete" placeholder="Enter a location" />
            <p class="help is-size-7 is-warning m-0 p-0" style="height: 20px">
              {{ err_message }}
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-fifth">
            <label class="label is-small">Door Number</label>
            <input :disabled="!editable" class="input is-small" type="text" v-model="DoorNumber" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">Apartment</label>
            <input :disabled="!editable" class="input is-small" v-model="Apartment" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">Street</label>
            <input :disabled="!editable" class="input is-small" v-model="Street" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">City</label>
            <input :disabled="!editable" class="input is-small" v-model="City" type="text" />
          </div>
          <div class="column is-one-fifth">
            <label class="label is-small">Postal Code</label>
            <input :disabled="!editable" class="input is-small" v-model="PostalCode" type="text" placeholder="T4A0X3" />
          </div>
        </div>

        <div class="columns" style="width: 100%">
          <div class="column is-one-quarter">
            <label class="label is-small">Type</label>
            <div class="control">
              <div class="select is-small is-fluid">
                <select :disabled="!editable" v-model="DistributionZoneType" style="width: 100%">
                  <template v-for="type in store.getters.DistributionZoneTypes">
                    <option :value="type" :key="type.value">{{ type.label }}</option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <label class="label is-small">Facility</label>
            <div class="control">
              <div class="select is-small">
                <select :disabled="!editable" v-model="Facility" style="width: 100%">
                  <template v-for="type in store.getters.Facility">
                    <option :value="type" :key="type">{{ type }}</option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="column" v-if="DistributionZoneType.label === 'STORE'">
            <label class="label is-small">Client</label>
            <div class="control">
              <div class="select is-small is-fluid" style="width: 100%">
                <select :disabled="!editable" v-model="Vendor" style="width: 100%">
                  <option class="is-size-7" v-for="vendor in store.state.vendors" :selected="Vendor === vendor.Id"
                    :key="vendor.Id" :value="vendor.Id">
                    {{ vendor.businessUnit }}***{{ vendor.subBusinessUnit }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-quarter">
            <label class="label is-small">Country</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="Country" style="width: 100%">
                  <template v-for="country in store.getters.Countries">
                    <option :value="country" :key="country.label" :selected="Country.label === country.label">{{ country.label }}</option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="column">
            <label class="label is-small">Coordinates (Lat/Lng)
              <span><button class="button is-small is-primary p-1 ml-1" style="height: 20px"
                  v-bind:class="{ 'is-loading': geocoding_loading }" @click="getCoordinates(Street, City, PostalCode)">
                  Get coordinates
                </button>
              </span></label>
            <div class="columns">
              <div class="column px-0">
                <input class="input is-small" v-model.number="Latitude" type="number" placeholder="Latitude" />
              </div>
              <div class="column pl-0">
                <input class="input is-small" v-model.number="Longitude" type="number" placeholder="Longitude" />
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-quarter">
            <label class="label is-small phone">Phone</label>
            <input :disabled="!editable" class="input is-small" type="tel" v-model="PhoneNumber"
              placeholder="xxxxxxxxxx" />
          </div>
          <div class="column is-one-quarter">
            <label class="label is-small">Size (Sq. Ft.)</label>
            <input :disabled="!editable" class="input is-small" v-model.number="Size" type="number"
              placeholder="Size" />
          </div>
          <div class="column is-one-quarter">
            <label class="label is-small">Number Of Employees</label>
            <input :disabled="!editable" class="input is-small" v-model.number="NumberOfEmployees" type="number"
              placeholder="Number Of Employees" />
          </div>

        </div>
        <div class="is-white is-flex is-flex-wrap-wrap" v-if="DistributionZoneType.label !== 'STORE'">
          <label class="tag m-2 checkbox">
            <input :disabled="!editable" class="is-small" v-model="IsFinalMile" type="checkbox" />
            Is Final Mile
          </label>
          <label class="tag m-2 checkbox">
            <input :disabled="!editable" class="is-small" v-model="IsForReverse" type="checkbox" />
            Used For Reverse
          </label>
          <label class="tag m-2 checkbox">
            <input :disabled="!editable" class="is-small" v-model="AllowCrossdock" type="checkbox" />
            Crossdock
          </label>
          <label class="tag m-2 checkbox">
            <input :disabled="!editable" class="is-small" v-model="AllowOvernightReception" type="checkbox" />
            Overnight Reception
          </label>
          <label class="tag m-2 checkbox">
            <input :disabled="!editable" class="is-small" v-model="CustomFields.shouldConsiderFuelSurcharge"
              type="checkbox" />
            Include In Fuel Surcharge Calculation
          </label>
        </div>
        <div class="my-3">Data Source</div>
        <label class="tag m-2 checkbox">
          <input :disabled="!editable" class="is-small" v-model="CustomFields.IsCDSRegion" type="checkbox" />
          Shyftbase
        </label>
        <label class="tag m-2 checkbox">
          <input :disabled="!editable" class="is-small" v-model="CustomFields.IsCustomerRegion" type="checkbox" />
          External
        </label>
      </div>

      <button v-if="editable" class="button is-small is-danger mx-1" @click="() => removeHub() ">
        Remove
      </button>

      <button v-if="editable" class="button is-small is-success mx-1" @click="() => saveToFirestore()">
        Save
      </button>
      <button v-if="editable" class="button is-small is-warning" @click="() => reset()">
        Reset
      </button>
    </div>
    <div style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
      " v-else>
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { hubsCollection, firestoreInstance } from "../store/fireStore";
import EventHandler from "../EventHandler";
import axios from "axios";
import * as leafletPip from "@mapbox/leaflet-pip";
import { geoJsonFeature } from "../views/utils"

export default {
  components: {
    PulseLoader,
  },
  data() {
    return {
      clientName: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
      Color: this.DistributionZone.Color || "#EEE",
      DistributionZoneName: this.DistributionZone.DistributionZoneName || "",
      DistributionZoneId: this.DistributionZone.DistributionZoneId || "",
      Street: this.DistributionZone.Street || "",
      DoorNumber: this.DistributionZone.DoorNumber || "",
      Apartment: this.DistributionZone.Apartment || "",
      City: this.DistributionZone.City || "",
      Province: this.DistributionZone.Province || "",
      PostalCode: this.DistributionZone.PostalCode || "",
      DistributionZoneType: this.store.getters.DistributionZoneTypes.find(type => type.value === this.DistributionZone.DistributionZoneType) || null,
      Facility: this.DistributionZone.Facility || "WAREHOUSE",
      Size: this.DistributionZone.Size || 0,
      NumberOfEmployees: this.DistributionZone.NumberOfEmployees || 0,
      PhoneNumber: this.DistributionZone.PhoneNumber || "",
      Vendor: this.DistributionZone.Vendor || "",
      AllowCrossdock: this.DistributionZone.AllowCrossdock || false,
      IsFinalMile: this.DistributionZone.IsFinalMile || false,
      IsForReverse: this.DistributionZone.IsForReverse || false,
      CustomFields: {
        IsCDSRegion: this.DistributionZone.CustomFields.IsCDSRegion || false,
        IsCustomerRegion:
          this.DistributionZone.CustomFields.IsCustomerRegion || false,
        shouldConsiderFuelSurcharge:
          this.DistributionZone.CustomFields.shouldConsiderFuelSurcharge ||
          false,
      },
      AllowOvernightReception:
        this.DistributionZone.AllowOvernightReception || false,
      loading: false,
      msg: "",
      Region: {
        name: "",
        id: this.DistributionZone.Region,
      },
      Latitude: this.coordinates
        ? this.coordinates.lat
        : this.DistributionZone.Center.latitude || 0,
      Longitude: this.coordinates
        ? this.coordinates.lng
        : this.DistributionZone.Center.longitude || 0,
      err_message: "",
      geocoding_loading: false,
      TimeZone: this.timeZone,
      Country: {
        label: this.DistributionZone.Country || "",
        value: this.DistributionZone.CountryCode || "",
      },
    };
  },
  props: {
    store: {
      type: Object,
    },
    map: {
      type: Object,
    },
    DistributionZone: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    coordinates: {
      type: Object,
    },
    timeZone: {
      type: String,
    }
  },
  computed: {
    getDistributionZoneType() {
      return this.store.getters.DistributionZoneTypes;
    },
  },
  watch: {
    DistributionZoneType: function (val) {
      // TODO: WE NEED TO SET THIS SETTING ON THE CONFIGURATIONS
      if (val.label === "STORE") {
        this.Color = "#DE5A35";
      } else if (val.label === "STEM") {
        this.Color = "#00d0c4";
      } else if (val.label === "SECONDARY") {
        this.Color = "#1184E8";
      } else if (val.label === "PRIMARY") {
        this.Color = "#007D6A";
      }
    },
  },
  methods: {
    closeModal() {
      EventHandler.$emit("closeModal");
    },
    reset() {
      this.Color = this.DistributionZone.Color || "#EEE";
      this.DistributionZoneName =
        this.DistributionZone.DistributionZoneName || "";
      this.DistributionZoneId = this.DistributionZone.DistributionZoneId || "";
      this.Street = this.DistributionZone.Street || "";
      this.DoorNumber = this.DistributionZone.DoorNumber || "";
      this.Apartment = this.DistributionZone.Apartment || "";
      this.City = this.DistributionZone.City || "";
      this.Province = this.DistributionZone.Province || "";
      this.PostalCode = this.DistributionZone.PostalCode || "";
      this.DistributionZoneType = this.store.getters.DistributionZoneTypes.find(type => type.value === this.DistributionZone.DistributionZoneType) || null;
      this.Facility = this.DistributionZone.Facility || "WAREHOUSE";
      this.Size = this.DistributionZone.Size || 0;
      this.NumberOfEmployees = this.DistributionZone.NumberOfEmployees || 0;
      this.PhoneNumber = this.DistributionZone.PhoneNumber || "";
      this.Vendor = this.DistributionZone.Vendor || "";
      this.AllowCrossdock = this.DistributionZone.AllowCrossdock || false;
      this.IsFinalMile = this.DistributionZone.IsFinalMile || false;
      this.IsForReverse = this.DistributionZone.IsForReverse || false;
      this.CustomFields = {
        IsCDSRegion: this.DistributionZone.CustomFields.IsCDSRegion || false,
        IsCustomerRegion:
          this.DistributionZone.CustomFields.IsCustomerRegion || false,
        shouldConsiderFuelSurcharge:
          this.DistributionZone.CustomFields.shouldConsiderFuelSurcharge ||
          false,
      };
      this.AllowOvernightReception =
        this.DistributionZone.AllowOvernightReception || false;
      this.Latitude = this.coordinates.lat;
      this.Longitude = this.coordinates.lng;
      this.TimeZone = this.timeZone
      this.Country = {
        label: this.DistributionZone.Country || "",
        value: this.DistributionZone.CountryCode || "",
      }
    },
    async removeHub() {
      /* Need to call a funciton, do proper checks before removal of a hub */
      /* Call a firebase function and pass on the DistributionZoneId */
      // await removeDistributionZone({ DistributionZoneId: this.DistributionZoneId });
      // this.closeModal();

      const hubDrawQuery = await hubsCollection
        .where("DistributionZoneId", "==", this.DistributionZoneId)
        .get();
      await hubDrawQuery.forEach(function (doc) {
        doc.ref.delete();
      });
      this.closeModal();
    },
    async saveToFirestore() {
      const {
        DistributionZoneId,
        Street,
        DoorNumber,
        Apartment,
        City,
        Province,
        PostalCode,
        DistributionZoneType,
        Facility,
        Size,
        NumberOfEmployees,
        PhoneNumber,
        AllowCrossdock,
        IsFinalMile,
        IsForReverse,
        CustomFields,
        AllowOvernightReception,
        Vendor,
        Color,
        Region,
        Latitude,
        Longitude,
        TimeZone,
        Country
      } = this;
      if (Vendor === "" && DistributionZoneType.label === "STORE") {
        this.msg = "Please Select a Vendor";
        return;
      }

      const Center = new firestoreInstance.GeoPoint(
        parseFloat(String(Latitude)),
        parseFloat(String(Longitude))
      );

      if (Street && City && Province && PostalCode && Country.label) {
        await hubsCollection
          .doc(this.DistributionZone.DistributionZoneId)
          .set(
            {
              Street,
              DoorNumber,
              Apartment,
              City,
              Province,
              PostalCode,
              AllowCrossdock,
              IsFinalMile,
              IsForReverse,
              CustomFields,
              AllowOvernightReception,
              DistributionZoneType: DistributionZoneType.value,
              Facility,
              Size,
              NumberOfEmployees,
              PhoneNumber,
              Color,
              Vendor: DistributionZoneType.label === "STORE" ? Vendor : "",
              Region: Region.id,
              Center,
              TimeZone,
              Country: Country.label,
              CountryCode: Country.value
            },
            { merge: true }
          )
          .then(() => {
            this.loading = false;
            EventHandler.$emit("closeModal");
            this.close(DistributionZoneId);
            this.map.panTo([Latitude, Longitude]);
            this.msg = "";
          })
          .catch((err) => {
            console.log("ERR: ", err);
          });
      } else {
        this.msg = "Name, street, city, province and postal code are required";
      }
    },
    async getCoordinates(street, city, postalCode, province, country) {
      this.geocoding_loading = true;
      const geocode_url =
        "https://us-central1-core-v2-34762.cloudfunctions.net/GEOCODE-functions-getOptimizerGeocode";
      const geocode_data = [
        {
          address: street + " " + city + " " + province + " " + country + " " + postalCode,
        },
      ];
      if (!city || !postalCode || !province || !country) {
        this.geocoding_loading = false;
        this.err_message = "Please enter a valid address";
        return;
      }
      await axios
        .post(geocode_url, { data: geocode_data })
        .then((response) => {
          this.geocoding_loading = false;
          if (response.data && response.data[0].geocodes) {
            const lat = response.data[0].geocodes.lat;
            const long = response.data[0].geocodes.long;

            this.Latitude = lat
            this.Longitude = long

            const gjLayer = window.L.geoJson(JSON.parse(geoJsonFeature));
            const firstPointResult = leafletPip.pointInLayer([long, lat], gjLayer);
            this.TimeZone = firstPointResult[0].feature.properties.tz_name1st || ""
          } else {
            this.err_message =
              "Coordinates not found. Please check the address or you can manually enter the coordinates";
          }
        })
        .catch((error) => {
          console.log("geocoding error: ", error);
          this.geocoding_loading = false;
          this.err_message = "Internal error. Please contact Shyftbase team";
        });
      this.geocoding_loading = false;
    },
    async onPlaceChanged() {
      this.err_message = "";
      const addressObject = this.autocomplete.getPlace();
      const address = addressObject.address_components;

      // if (!address.map((a) => a.long_name).includes(this.Province)) {
      //   this.err_message =
      //     "zone cannot be outside of the province of " + this.Province;
      //   return;
      // }
      const province = address
        ? address.filter((address_component) =>
          ["administrative_area_level_1", "administrative_area_level_2"].some(
            (word) => address_component.types.includes(word)
          )
        )
        : null;

      const streetAddress = address
        ? address
          .filter((address_component) =>
            [
              "floor",
              "room",
              "route",
              "street_number",
              "street_address",
            ].some((word) => address_component.types.includes(word))
          )
          .map((a) => a.short_name)
          .join(" ")
        : null;

      const city = address
        ? address.filter((address_component) =>
          ["locality", "colloquial_area"].some((word) =>
            address_component.types.includes(word)
          )
        )
        : null;

      const postalCode = address
        ? address.filter((address_component) =>
          ["postal_code"].some((word) =>
            address_component.types.includes(word)
          )
        )
        : null;
      const country = address ? address.filter(address_component => ["country"].some(word => address_component.types.includes(word))) : null;

      const _street = streetAddress;
      const _city = city.length && city[0] ? city[0].long_name : "";
      const _postalCode = postalCode.length
        ? postalCode[0].short_name || ""
        : "";

      const _country = country.length ? country[0].long_name : ''
      const _countryCode = country.length ? country[0].short_name : ''
      const _province = province.length ? province[0].long_name : this.Province;

      this.Street = _street;
      this.City = _city;
      this.PostalCode = _postalCode;
      this.Country = {
        label: _country,
        value: _countryCode
      }
      this.Province = _province;
      await this.getCoordinates(_street, _city, _postalCode, _province, _country);
    },
  },
  mounted() {
    const regions = this.store.state.regions;
    // const currentRegionSelected = this.store.state.region;
    const selectedRegion = regions.find(
      (region) => region.id === this.Region.id
    );
    this.Region.name = selectedRegion.name;
    this.Latitude = this.coordinates.lat;
    this.Longitude = this.coordinates.lng;
    this.TimeZone = this.timeZone
    this.err_message = "";
    const input = this.$refs.autocomplete;

    const options = {
      types: ["geocode"], // Restrict to addresses
      // Add any additional options as needed
    };

    // eslint-disable-next-line
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  },
};
</script>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
  background: #e93434;
  color: #ffffff;
}

select,
.select {
  width: 100%;
}
</style>
