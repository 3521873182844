<template>
  <div>
    <div class="" v-if="!loading">
      <div
        class="tabs is-toggle is-centered is-rounded is-small is-success my-4"
      >
        <ul class="p-2">
          <li :class="{ 'is-active': activeTab === 1 }">
            <a @click="activeTab = 1">
              <span>Zone</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 2 }">
            <a @click="activeTab = 2">
              <span>Zone Properties</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 3 }">
            <a @click="activeTab = 3">
              <span>Days Of Service</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 4 }">
            <a @click="activeTab = 4">
              <span>Distribution Zones</span>
            </a>
          </li>
        </ul>
      </div>
      <md-list class="md-double-line" v-if="activeTab === 1">
        <div class="is-size-6 has-text-weight-semibold has-text-danger">
          {{ msg }}
        </div>
        <div class="has-text-weight-semibold">Zone</div>
        <multiselect
          v-if="editable"
          class="my-3"
          v-model="zone"
          :options="zoneList"
          label="value"
          :close-on-select="true"
          track-by="value"
        ></multiselect>
        <div v-else>{{ zone.value }}</div>
        <div v-if="editable" class="columns is-white">
          <div class="column has-text-centered" v-if="nameChanged">
            <button
              class="is-fullwidth button is-small is-success"
              @click="editFirestore"
            >
              Save
            </button>
          </div>
        </div>
        <div v-if="editable" class="columns notification is-white">
          <div class="column has-text-centered">
            <button
              class="button is-warning is-small is-fullwidth"
              @click="closeModal()"
              v-if="!editNameMode"
            >
              Close
            </button>
          </div>
          <div v-if="editable" class="column has-text-centered">
            <button
              class="button is-small is-fullwidth"
              @click="addMoreShape()"
            >
              Add By Drawing
            </button>
          </div>
          <div v-if="editable" class="column has-text-centered">
            <button
              class="button is-small is-fullwidth"
              @click="deletePartsShape()"
            >
              Delete By Drawing
            </button>
          </div>
          <div v-if="editable" class="column has-text-centered">
            <button
              :class="
                isFSAButtonLoading
                  ? 'is-fullwidth button is-success is-small is-loading'
                  : 'is-fullwidth button is-success is-small'
              "
              @click="editSelectableFSAMode()"
            >
              Edit In FSA Mode
            </button>
          </div>
        </div>
      </md-list>
      <md-list class="md-double-line" v-if="activeTab === 2">
        <div class="is-size-6 has-text-weight-semibold has-text-danger">
          {{ msg }}
        </div>
        <div
          class="is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-baseline my-3"
        >
          <template v-if="environment.toLowerCase() === 'logistics'">
            <div class="columns" style="width: 100%">
              <div class="column is-one-fifths">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Base Charge:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.BaseCharge"
                />
              </div>
              <div class="column is-one-fifths">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Delivery Surcharge:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.DeliverySurcharge"
                />
              </div>
              <div class="column is-one-fifths">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Unit Charge:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.UnitCharge"
                />
              </div>
              <div class="column is-one-fifths">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Product Surcharge:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.ProductSurcharge"
                />
              </div>
              <div class="column is-one-fifths">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Maximum Charge:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.MaximumCharge"
                />
              </div>
            </div>
            <div class="columns" style="width: 100%"></div>
          </template>
          <template v-if="environment.toLowerCase() === 'installation'">
            <div class="columns" style="width: 100%">
              <div class="column is-one-third">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Simple Installation:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="text"
                  v-model="zoneProps.SimpleInstallation"
                />
              </div>
              <div class="column is-one-third">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Complex Installation:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="text"
                  v-model="zoneProps.ComplexInstallation"
                />
              </div>
              <div class="column is-one-third">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Inst. Trip Charge:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.InstTripCharge"
                />
              </div>
            </div>
          </template>
          <template v-if="environment.toLowerCase() === 'services'">
            <div class="columns" style="width: 100%">
              <div class="column">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Technician
                </div>
                <div
                  v-for="t in Object.keys(zoneProps.Technicians || {})"
                  :key="t"
                >
                  {{ t }}
                </div>
              </div>
            </div>
          </template>
          <template v-if="environment.toLowerCase() !== 'mieleprofessionals'">
            <div class="columns" style="width: 100%">
              <div class="column is-one-quarter">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                 Min {{store.getters.DistanceUnit.selected}}:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.min"
                />
              </div>
              <div class="column is-one-quarter">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Max {{store.getters.DistanceUnit.selected}}:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="number"
                  v-model="zoneProps.max"
                />
              </div>
              <div class="column is-one-quarter">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Color:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  placeholder="#FFFFFF"
                  type="text"
                  v-model="zoneProps.color"
                />
              </div>
              <div class="column is-one-quarter">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Recycling:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="text"
                  v-model="zoneProps.Recycling"
                />
              </div>
            </div>
          </template>
          <template>
            <div class="columns"  style="width: 50%; margin: 0 auto">
              <div class="column is-one-half">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Description English:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="text"
                  v-model="zoneProps.DescriptionEnglish"
                />
              </div>
            </div>
          </template>
          <template v-if="environment.toLowerCase() !== 'mieleprofessionals'">
            <div class="columns" style="width: 100%">
              <div class="column is-one-half">
                <div
                  class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
                >
                  Description French:
                </div>
                <input
                  :disabled="!editable"
                  class="input is-small"
                  type="text"
                  v-model="zoneProps.DescriptionFrench"
                />
              </div>
            </div>
          </template>
          <template v-if="environment.toLowerCase() === 'mieleprofessionals'">
            <div style="width: 50%; margin: 0 auto">
              <div
                class="is-size-7 is-flex is-flex-direction-row has-text-weight-semibold"
              >
                Travel Fee
              </div>
              <input
                :disabled="!editable"
                class="input is-small"
                type="number"
                v-model="zoneProps.TravelFee"
              />
            </div>
          </template>
        </div>
        <!-- <div class="notification is-white">
          <input class="input is-small" type="text" v-model="zone" placeholder="Insert new zone name" />
        </div> -->
        <div v-if="editable" class="columns notification is-white">
          <div class="column has-text-centered">
            <button
              class="button is-warning is-small is-fullwidth"
              @click="closeModal()"
              v-if="!editNameMode"
            >
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button
              :class="
                isEditZonePropsButtonLoading
                  ? 'button is-success is-light is-small is-loading is-fullwidth'
                  : 'button is-success is-small is-fullwidth'
              "
              @click="editZoneProperties()"
            >
              Save Zone Properties
            </button>
          </div>
        </div>
      </md-list>
      <md-list class="md-double-line md-dense" v-if="activeTab === 3">
        <div class="notification is-white">
          <template>
            <div>
              <multiselect
                v-if="editable"
                v-model="daysAssigned"
                :options="days"
                label="day"
                :multiple="true"
                :close-on-select="false"
                :taggable="true"
                track-by="day"
              ></multiselect>
              <div v-else>{{ daysAssigned.map((d) => d.day).join(", ") }}</div>
            </div>
          </template>
        </div>
        <div v-if="editable" class="columns notification is-white">
          <div class="column has-text-centered">
            <button
              class="is-fullwidth button is-warning is-small"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button
              class="is-fullwidth button is-small is-success"
              @click="assignDays"
            >
              Assign Days
            </button>
          </div>
        </div>
      </md-list>
      <md-list class="md-double-line md-dense" v-if="activeTab === 4">
        <div class="notification is-white">
          <template>
            <div>
              <multiselect
                v-if="editable"
                v-model="hubsAssigned"
                :options="DISTRIBUTIONZONES"
                label="DistributionZoneName"
                :close-on-select="false"
                :taggable="true"
                track-by="DistributionZoneId"
              ></multiselect>
              <div v-else>{{ hubsAssigned.join(", ") }}</div>
            </div>
          </template>
        </div>
        <div v-if="editable" class="columns notification is-white">
          <div class="column has-text-centered">
            <button
              class="button is-warning is-small is-fullwidth"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button
              class="button is-small is-success is-fullwidth"
              @click="assignHub"
            >
              Assign DistributionZone
            </button>
          </div>
        </div>
      </md-list>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
      "
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Multiselect from "vue-multiselect";
import store from "../store";
import { zonesInfoCollection } from "../store/fireStore";
import { changeZoneId } from "../store/mongoStore";
import EventHandler from "../EventHandler";
import { unitConverter } from "@/utils/utils";

export default {
  components: {
    PulseLoader,
    Multiselect,
  },
  data() {
    return {
      environment: this.store.state.environment,
      zone: {
        value: "",
        zoneNameId: "",
        shapesId: "",
      },
      msg: "",
      loading: false,
      activeTab: 1,
      currentIndex: 0,
      days: [
        { day: "Sunday" },
        { day: "Monday" },
        { day: "Tuesday" },
        { day: "Wednesday" },
        { day: "Thursday" },
        { day: "Friday" },
        { day: "Saturday" },
      ],
      daysAssigned: [],
      hubsAssigned: [],
      editNameMode: false,
      isFSAButtonLoading: false,
      isEditZonePropsButtonLoading: false,
      zoneRegion: "",
      zoneProps: {
        min: 0,
        max: 0,
        BaseCharge: 0,
        DeliverySurcharge: 0,
        UnitCharge: 0,
        ProductSurcharge: 0,
        InstTripCharge: 0,
        MaximumCharge: 0,
        SimpleInstallation: "",
        ComplexInstallation: "",
        Recycling: "",
        DescriptionEnglish: "",
        DescriptionFrench: "",
        color: "",
        Technicians: {},
        Province: "",
        environment: "",
        shared: false,
        value: "",
        TravelFee: 0,
      },
    };
  },
  props: {
    coordinates: {
      type: Array,
    },
    shape: {
      type: Object,
    },
    vehicles: {
      type: Array,
    },
    store: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
  },
  methods: {
    closeModal() {
      EventHandler.$emit("closeModal");
      this.close();
      this.editNameMode = false;
    },
    addMoreShape() {
      this.addMoreShapeFunc(true);
    },
    deletePartsShape() {
      this.addMoreShapeFunc(false);
    },
    convertDistance(distance, isSaving = false) {
      const targetUnit = isSaving ? store.getters.DistanceUnit.dataSourceUnit : store.getters.DistanceUnit.selected;
      const sourceUnit = isSaving ? store.getters.DistanceUnit.selected : store.getters.DistanceUnit.dataSourceUnit;
      return unitConverter(sourceUnit, targetUnit, distance);
    },
    editSelectableFSAMode() {
      this.isFSAButtonLoading = true;
      setTimeout(async () => {
        await this.selectedFSAModeEdit();
        this.isFSAButtonLoading = false;
      }, 500);
    },
    async editFirestore() {
      this.loading = true;
      const zone = this.zone.value ? this.zone.value.trim() : "";
      const zoneId = this.zone.zoneNameId ? this.zone.zoneNameId.trim() : "";
      const shape = this.shape;
      const shapeId = shape.id.trim();
      if (zone && zoneId) {
        try {
          await changeZoneId(zoneId, shapeId);
          // this.editZone(zone, this.shape.zone)
          this.shape.zone = zone;
          this.loading = false;
          this.close();
          this.editNameMode = false;
          // await this.renderMapFunc()
        } catch (err) {
          this.loading = false;
          this.msg = "Error in editing zone";
        }
      } else {
        this.loading = false;
        this.msg = "Please select name";
      }
    },
    async onChangeTruck(e) {
      const [, index] = JSON.parse(e.target.value);
      this.currentIndex = index;
    },
    async assignDays() {
      this.loading = true;
      const daysToSave = this.daysAssigned.map((d) => d.day);
      try {
        await zonesInfoCollection.doc(this.shape.zoneId).update({
          DaysAssigned: daysToSave,
        });
        this.loading = false;
        this.close();
        await this.renderMapFunc();
      } catch (err) {
        this.loading = false;
        this.msg = "Zone name existing";
      }
    },
    async assignHub() {
      const hubIndexes =
        this.hubsAssigned && this.hubsAssigned.map((h) => h.DistributionZoneId);
      try {
        await zonesInfoCollection.doc(this.shape.zoneId).update({
          HubsAssigned: hubIndexes,
        });
        this.loading = false;
        this.close();
        await this.renderMapFunc();
      } catch (err) {
        this.loading = false;
        this.msg = "Assign Error, Please try again";
      }
    },
    async editZoneProperties() {
      console.log(this.convertDistance(this.zoneProps.min, true))
      this.isEditZonePropsButtonLoading = true;
      try {
        await zonesInfoCollection.doc(this.shape.zoneId).set(
          {
            BaseCharge: Number(this.zoneProps.BaseCharge),
            DeliverySurcharge: Number(this.zoneProps.DeliverySurcharge),
            UnitCharge: Number(this.zoneProps.UnitCharge),
            ProductSurcharge: Number(this.zoneProps.ProductSurcharge),
            InstTripCharge: Number(this.zoneProps.InstTripCharge),
            MaximumCharge: Number(this.zoneProps.MaximumCharge),
            SimpleInstallation: this.zoneProps.SimpleInstallation,
            ComplexInstallation: this.zoneProps.ComplexInstallation,
            Recycling: this.zoneProps.Recycling,
            DescriptionEnglish: this.zoneProps.DescriptionEnglish,
            DescriptionFrench: this.zoneProps.DescriptionFrench,
            color: this.zoneProps.color,
            min: this.convertDistance(this.zoneProps.min, true),
            max: this.convertDistance(this.zoneProps.max, true),
            TravelFee: this.zoneProps.TravelFee,
          },
          { merge: true }
        );
        this.isEditZonePropsButtonLoading = false;
        this.close();
        await this.renderMapFunc();
      } catch (err) {
        this.isEditZonePropsButtonLoading = false;
        this.msg = "Error on updating zone properties";
      }
    },
  },
  computed: {
    zoneList() {
      const zoneNames = store.state.zoneInformations.map((zone) => ({
        value: zone.value.trim(),
        zoneNameId: zone.id.trim(),
      }));
      return zoneNames;
    },
    currentTruck() {
      return this.store.state.selectedVehicles[this.currentIndex];
    },
    selectedTrucks() {
      return this.store.state.vehicles;
    },
    DISTRIBUTIONZONES() {
      return this.store.state.DISTRIBUTIONZONES;
    },
    nameChanged() {
      return this.shape.zone.trim() !== this.zone.value.trim();
    },
    hubsToBeAssigned: {
      get: function () {
        return this.store.state.hubsToBeAssigned;
      },
      set: function (newValue) {
        console.log("newValue: ", newValue);
        this.store.dispatch("setHubsToBeAssigned", [...newValue]);
      },
    },
  },
  mounted() {
    this.zone.value = this.shape.zone;
    this.zone.zoneNameId = this.shape.zoneNameId;
    this.zoneProps = {
      color: this.shape.color || "",
      BaseCharge: this.shape.BaseCharge || 0,
      DeliverySurcharge: this.shape.DeliverySurcharge || 0,
      UnitCharge: this.shape.UnitCharge || 0,
      ProductSurcharge: this.shape.ProductSurcharge || 0,
      InstTripCharge: this.shape.InstTripCharge || 0,
      MaximumCharge: this.shape.MaximumCharge || 0,
      SimpleInstallation: this.shape.SimpleInstallation || "",
      ComplexInstallation: this.shape.ComplexInstallation || "",
      Recycling: this.shape.Recycling || "",
      DescriptionEnglish: this.shape.DescriptionEnglish || "",
      DescriptionFrench: this.shape.DescriptionFrench || "",
      min: this.convertDistance(this.shape.min || 0, false),
      max: this.convertDistance(this.shape.max || 0, false),
      Technicians: this.shape.Technicians || {},
      TravelFee: this.shape.TravelFee || 0,
      environment: this.shape.environment || "",
    };
    if (this.shape.DaysAssigned) {
      this.daysAssigned = this.shape.DaysAssigned.map((m) => ({ day: m }));
    }
    if (this.shape.HubsAssigned) {
      const hubsAssigned = this.shape.HubsAssigned.map((zoneHub) => {
        const DistributionZone = this.DISTRIBUTIONZONES.find(
          (h) => h.DistributionZoneId === zoneHub
        );
        return DistributionZone;
      });
      this.hubsAssigned = hubsAssigned;
    }
    const regions = this.store.state.regions;
    const selectedRegion = regions.find(
      (region) => region.id === this.shape.region
    );
    this.zoneRegion = selectedRegion.name || "";
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
  background: #e93434;
  color: #ffffff;
}
</style>
