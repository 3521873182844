<template>
  <section style="max-width: 500px; margin: 0 auto" class="section is-small">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Select a Module</p>
      </header>
      <div class="card-image">
        <figure class="image">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FA_simple_and_abstract_black_and_white_illustration_converted.png?alt=media&token=3500f3d7-0a52-43a2-b7df-62b5552ce94e"
            alt="Placeholder image"
          />
        </figure>
      </div>
      <footer class="card-footer">
        <a href="https://demooptima.web.app" class="card-footer-item has-text-success">Routing Module</a>
        <a href="/home" class="card-footer-item has-text-success">Network Module</a>
      </footer>
    </div>
  </section>
</template>

<script>
import { auth } from "@/store/fireStore";
export default {
  name: "ModuleSelector",
  data() {
    return {
      client: process.env.VUE_APP_SHYFTBASE_CUSTOMER_ID,
    };
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await this.$store.dispatch("updateUser", user);
      }
    });
    await this.$store.dispatch("onLoad");
  }
};
</script>
