import XLSX from 'xlsx';
import convert, { Unit } from 'convert-units';

export const unitConverter = (dataUnit: string, targetUnit: string, value: any) => {
  if (dataUnit === targetUnit || !value) return value;

  const amount = typeof value === 'string' ? parseFloat(value) : value;

  const result = convert(amount).from(dataUnit.toLowerCase() as Unit).to(targetUnit.toLowerCase() as Unit);

  return result;
}

export const to_json = (workbook: any): string => {
  const result: any = {};
  workbook.SheetNames.forEach(function (sheetName: any) {
    /* ATTENTION
                RAW: NEED TO BE PRESENT TO KEEP DATA STRING, OTHER WISE JSON.STRINGIFY/JSON.PARSE
                CHANGES LARGE NUMBERS, THEREFORE BARCODE VALUES CHANGE DURING THE IMPORT PROCESS
            */
    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      raw: false,
      header: 1,
      blankrows: false,
      defval: ''
    });
    if (roa.length) result[sheetName] = roa;
  });
  // return JSON.stringify(result, 2, 2);
  return JSON.stringify(result, null, 2);
};

export const processExcel = (data: any): string => {
  const workbook = XLSX.read(data, {
    type: 'binary',
    cellDates: true,
    cellNF: false,
    cellText: true,
    raw: true
  });
    // var firstSheet = workbook.SheetNames[0];
  const toJsonData = to_json(workbook);
  return toJsonData
};

export const importFile = (evt: any) => {
  const f = evt.target.files[0];
  if (f) {
    const r = new FileReader();
    r.onload = (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const contents = processExcel(e.target.result);
    }
    r.readAsBinaryString(f);
  } else {
    console.log("Failed to load file");
  }
}
